export const isCardWild = ({suit,rank}) => {
    return suit === 'joker' || rank === '2' ;
}

export const hasEnoughWildCardsInMeld = (meld) => {
    const wildCards = meld.meldCards.filter(card => card.isWild).length;
    const naturalCards = meld.meldCards.filter(card => !card.isWild).length;    
    return Math.min((naturalCards/2) - wildCards, 7 - meld.meldCards.length) <= 0;
};

export const hasMinimumNaturalCards = (meld) => {
    return meld.meldCards.filter(card => !card.isWild).length >= 2;
}

export const isCanasta = (meld) => {
    return meld && meld.meldCards.length >= 7;
}

export const getTotalNaturalCanastas = (team) => team && team.melds ? 
team.melds.reduce((sum, meld) => meld.isNatural && isCanasta(meld) ? sum + 1 : sum ,0) : 0;

export const getTotalUnnaturalCanastas = (team) => {
    if (!team || !team.melds) return 0;

    return team.melds.reduce((sum, meld) => 
    !meld.isNatural && isCanasta(meld) ? sum + 1 : sum,0);
};

export const naturalCanastaIsNotAllowed = (meld, team, game) => {
    if (!meld || !team || !game) {
        return false;
    }

    return (
        meld && meld.meldCards.length === 6 &&
        meld.meldCards.every(card => !card.isWild) &&
        getTotalNaturalCanastas(team) >= game.gameConfig.naturalCanastasNeeded &&
        team.unnaturalCanastasLeft > 0
    );
}

export const unnaturalCanastaIsNotAllowed = (meld, team, game) => {
    if (!meld || !team || !game) {
        return false;
    }
    return (
        meld && meld.meldCards.length === 6 &&
        meld.meldCards.some(card => card.isWild) &&
        getTotalUnnaturalCanastas(team) >= game.gameConfig.unnaturalCanastasNeeded &&
        team.naturalCanastasLeft > 0
    );
}

export const isPlayerCannotMakeMeldWithACard = (cardRank, meld, team) => {
    if (!team || !team.teamPlayers || !team.teamPlayers[0] || !team.teamPlayers[0].hand) {
        return false;
    }
    const playerCards = team.teamPlayers[0].hand.handCards;
    if (!playerCards) return false;
    const numberOfSpecificCards = playerCards.reduce((sum, {card}) => 
    card.cardRank.value == cardRank ? sum + 1 : sum, 0);
    const numberOfWildCards = playerCards.reduce((sum, {card}) => card.isWild ? sum + 1 : sum, 0);

    return (
        (!meld || meld.meldCards.length === 0)  && 
        (numberOfSpecificCards === 1 ||    
        (numberOfSpecificCards === 2 && numberOfWildCards === 0))
    );
}